<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-15 18:56:23
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-24 19:13:49
 * @Description:
-->
<template>
  <div class="corpModel">
    <div class="operationButton">
      <el-button size="small" @click="$emit('exits', addAndEait)">取消</el-button>
      <el-button type="primary" class="butColor" @click="saveModel(gowthStage, titleForm)" size="small">保存</el-button>
    </div>
    <div class="conter">
      <div class="proctImg essentialImg">
        <el-upload v-if="!eaitState" class="avatar-uploader" action="/imgUpdata" :show-file-list="false" :on-success="plantImghandleSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="plantImg" :src="plantImg" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <img v-else-if="eaitState" :src="plantImg" class="avatar" />
      </div>
      <div class="essential">
        <div>
          <h3>基本信息</h3>
          <div v-if="$attrs['nonBatch'] == 'crops'" class="public titleFormCon">
            <el-form ref="titleForm" :rules="rules" class="titleForm" :model="titleForm" label-width="80px">
              <el-form-item label="作物名称" prop="plantName">
                <el-input v-model="titleForm.plantName" :readonly="eaitState" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="作物类别" prop="plantType">
                <el-input v-model="titleForm.plantType" :readonly="eaitState" placeholder="请输入"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-if="$attrs['nonBatch'] == 'eait' || $attrs['nonBatch'] == 'add'" class="public titleFormCon">
            <el-form ref="titleForm" :rules="rules" class="batchBox" :model="titleForm" label-width="80px">
              <el-form-item label="作物名称" prop="productName">
                <el-select v-model="titleForm.productName" @change="cropNameChange" placeholder="请选择作物名称">
                  <el-option v-for="item in $attrs['cropsList']" :key="item.id" :label="item.plantName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="作物类别" prop="plantType">
                <el-input v-model="titleForm.plantType" :readonly="eaitState" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="地块名称" prop="plotName">
                <el-input disabled v-model="titleForm.plotName" :readonly="eaitState" placeholder="请输入"></el-input>
                <!-- <el-select v-model="titleForm.plotId" placeholder="请选择地块名称" @change="massifChange">
                  <el-option v-for="item in $attrs['massifList']" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="种植批次" prop="batchNumber">
                <el-input v-model="titleForm.batchNumber" :readonly="eaitState" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="面积信息" prop="plantingArea">
                <el-input disabled v-model="titleForm.plantingArea" :readonly="eaitState" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="种植时间" prop="plantingTime" class="plantingClassInput">
                <el-date-picker v-model="titleForm.plantingTime" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
              <el-form-item label="摄像头" prop="cameraIdsFrom">
                <el-select v-model="titleForm.cameraIdsFrom" @change="cameraChange" multiple :collapse-tags="true" placeholder="请选择">
                  <el-option v-for="item in cameraList" :key="item.value" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="环境设备" prop="deviceIdsFrom">
                <el-select v-model="titleForm.deviceIdsFrom" @change="deviceChange" multiple :collapse-tags="true" placeholder="请选择">
                  <el-option v-for="item in equipmentList" :key="item.Nid" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!--  -->
        <div>
          <div class="grow">
            <h3>生长周期</h3>
            <!-- <el-button class="growBut" @click="addGrowthStage" size="mini">添加生长阶段</el-button> -->
          </div>
          <!--  Process information -->
          <div class="information">
            <div v-for="item in gowthStage" :key="item.id">
              <input class="written" :readonly="eaitState" v-model="item.name" type="text" />
              <div class="gowthStageItemBox">
                <div class="gowthStageItem" v-for="self in item.productCycleModelInfoBeanList" :key="self.id">
                  <div class="imgUpload">
                    <el-upload
                      v-if="!eaitState"
                      class="avatar-uploader"
                      action="/imgUpdata"
                      :show-file-list="false"
                      :on-success="(file) => handleSuccess(file, self)"
                      :before-upload="beforeAvatarUpload"
                    >
                      <img v-if="self.url" :src="self.url" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    <img v-else-if="eaitState" :src="self.url" class="avatar" />
                  </div>
                  <div>
                    <el-form class="phase" ref="phaseDetails" :model="phaseDetails">
                      <el-form-item>
                        <el-input v-model="self.name" :readonly="eaitState" placeholder="请输入"><template slot="append"></template></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input v-model="self.day" :readonly="eaitState" placeholder="请输入"><i slot="suffix" style="font-style:normal;margin-right: 30px;color:black">天</i></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <!--  -->
                <div class="addGowthStage">

                  <span @click="addModel(item)" class="el-icon-plus"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { batch } from '@/utils/required.js';
export default {
  data() {
    return {
      typeList: [{ name: '粮食作物' }, { name: '豆类作物' }],
      titleForm: {
        productName: '',
        plantType: '',
        plotId: '',
        batchNumber: '',
        plantingArea: '',
        plantingTime: '',
        cameraIdsFrom: '',
        deviceIdsFrom: '',
        plantName: '',
        plotName: '',
      },
      gowthStage: [
        {
          id: 0,
          name: '',
          productCycleModelInfoBeanList: [{ name: '', day: '', url: '' }],
        },
      ],
      plantImg: null,
      phaseDetails: {},
      imageUrl: '',
      eaitState: false,
      address: null,
      productionList: [],
      addAndEait: false,
      cameraList: [],
      equipmentList: [],
      rules: batch,
      //   nonBatch:true
    };
  },
  computed: {
    // countUrl:{
    //   get(newValuew) {
    //        //console.log(newValuew)
    //     return newValuew;
    //   },
    //   set(newValue) {
    //     //console.log(newValue)
    //   },
    // },
  },
  mounted() {
    this.requestproduction();
    this.requestCamera();
    this.$nextTick(() => {
      this.$refs.titleForm.resetFields();
      setTimeout(() => {
        if (this.$attrs['echoStatus']) {
          this.titleForm.plotName = this.$attrs['batchWkt'].name;
          this.titleForm.plantingArea = this.$attrs['batchWkt'].size;
          this.$forceUpdate();
          this.titleForm.plotId = this.$attrs['batchWkt'].id;
          this.titleForm.massifId = this.$attrs['batchWkt'].id;
        }
      }, 1000);
    });
  },
  methods: {
    /**
     * @description: 摄像头
     */
    requestCamera() {
      this.$get('/cameraManage/getCameraList', { page: 1, limit: 1000 }).then((res) => {
        if (res.data.state == 'success') {
          //console.log(res);
          this.cameraList = res.data.datas;
        }
      });
      this.$get('/deviceManage/getDeviceLists', { page: 1, limit: 1000 ,groupId:1}).then((res) => {
        if (res.data.state == 'success') {
          //console.log(res);
          this.equipmentList = res.data.datas;
        }
      });
    },
    cameraChange(val) {
      this.requestCamera();
      //console.log(val);
      this.titleForm.cameraIds = val.join(',');
    },
    deviceChange(val) {
      this.requestCamera();
      //console.log(val);
      this.titleForm.deviceIds = val.join(',');
    },
    addModel(item) {
      if (this.eaitState) return;
      item.productCycleModelInfoBeanList.push({ name: '', day: '', url: '' });
    },
    requestproduction() {
      this.$get('/productCycle/qurryProductCycle', { page: 1, size: 100000 }).then((res) => {
        if (res.data.state == 'success') {
          this.productionList = res.data.datas;
        }
      });
    },
    addGrowthStage() {
      if (this.eaitState) return;
      this.gowthStage.push({ id: this.gowthStage.length + 1, name: '生育期', productCycleModelInfoBeanList: [{ name: '', day: '', url: '' }] });
    },
    handleSuccess(file, self) {
      self.url = 'https://public.half-half.cn/' + file.data.fileName;
    },
    plantImghandleSuccess(file) {
      this.plantImg = 'https://public.half-half.cn/' + file.data.fileName;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPng = file.type === 'image/png';
      if (isJPG || isPng) {
        return true;
      }
      this.$message.info('请检查上传格式！');
      this.fileList = [];
      return false;
    },
    /**
     * @description: 保存
     * @param {*} data
     * @param {*} nonBatch true 生产周期配置   fasle 种植批次
     */
    saveModel(data, base) {
      if (!this.plantImg) this.$message.info('请上传图片');
      this.$refs['titleForm'].validate((valid) => {
        if (valid) {
          if (this.$attrs['nonBatch'] == 'crops') {
            let { plantName, plantType, id } = this.titleForm;
            let datas = { id, plantName, plantType, plantImg: this.plantImg, processInfo: JSON.stringify(data) };
            if (this.address == 'add') delete datas.id;
            this.$emit('saveModel', datas, this.address);
          } else if (this.$attrs['nonBatch'] == 'eait') {
            //修改
            if (!base.cameraIds) base.cameraIds = base.cameraIdsFrom.join(',');
            if (!base.deviceIds) base.deviceIds = base.deviceIdsFrom.join(',');
            delete base.cameraIdsFrom;
            delete base.deviceIdsFrom;
            let batch = { processInfo: data, ...base, productImg: this.plantImg };
            // //console.log(data);
            // //console.log(base);
            this.$emit('saveModel', batch, 'eait');
          } else if (this.$attrs['nonBatch'] == 'add') {
            //保存
            delete base.cameraIdsFrom;
            delete base.deviceIdsFrom;
            let batch = { processInfo: data, ...base, productImg: this.plantImg };
            this.$emit('saveModel', batch, 'add');
          }
        }
      });
    },
    // exitDoig() {
    //   this.$emit('exits');
    // },
    /**
     * @description: 生产周期配置编辑
     * @param {*} val 值
     * @param {*} state 编辑1 查看0
     */

    taitModel(val, state) {
      this.$nextTick(() => {
        // let { plantImg, plantName, plantType, processInfo, id } = val;
        let { plantImg, plantName, plantType, productCycleModelBeanList, id } = val;
        this.plantImg = plantImg;
        this.titleForm = { plantName, plantType, id };
        // this.gowthStage = JSON.parse(processInfo);productCycleModelInfoBeanList
        this.gowthStage = productCycleModelBeanList;
        if (productCycleModelBeanList[0].productCycleModelInfoBeanList.length == 0) {
          this.gowthStage[0].productCycleModelInfoBeanList = [{ name: '', day: '', url: '' }];
        }
        this.address = 'eait';
        if (state == 0) this.eaitState = true;
      });
    },
    /**
     * @description:
     * @param {*}id
     */

    cropNameChange(id) {
      this.$get('/productCycle/fatchProductCycleById', { id }).then((res) => {
        if (res.data.state == 'success') {
          let batchModel = res.data.data.productCycle.productCycleModelBeanList;
          if (batchModel && batchModel[0].productCycleModelInfoBeanList.length != 0) this.gowthStage = batchModel;
          this.titleForm.plantType = res.data.data.productCycle.plantType;
          this.titleForm.plantName = this.$attrs['cropsList'].find((v) => id == v.id).plantName;
        }
      });
    },
    massifChange(val) {
      this.titleForm.massifId = this.$attrs['massifList'].find((element) => val == element.id).id;
      this.titleForm.plotName = this.$attrs['massifList'].find((element) => val == element.id).name;
    },
    /**
     * @description:  种植批次回显
     * @param {*}
     */
    echoPlantingBatch() {
      // //console.log(this.$attrs['productCycle']);
      this.addAndEait = true;
      let datas = this.$attrs['productCycle'].productCycle.productCycleModelBeanList;
      // //console.log(this.$attrs['productCycle']);
      let plantingBatchBean = this.$attrs['productCycle'].plantingBatchBean;
      if (datas.length == 0) return;
      this.gowthStage = datas;
      this.gowthStage.plantType = plantingBatchBean.plantType;
      this.titleForm = plantingBatchBean;
      this.plantImg = this.$attrs['productCycle'].productCycle.plantImg;
      let cameraIds = this.$attrs['productCycle'].plantingBatchBean.cameraIds;
      let deviceIds = this.$attrs['productCycle'].plantingBatchBean.deviceIds;
      if (RegExp(/,/g).test(cameraIds)) {
        this.titleForm.cameraIdsFrom = cameraIds.split(',').map((v) => Number(v));
      } else {
        this.titleForm.cameraIdsFrom = [Number(cameraIds)];
      }
      if (RegExp(/,/g).test(deviceIds)) {
        this.titleForm.deviceIdsFrom = deviceIds.split(',').map((v) => Number(v));
      } else {
        this.titleForm.deviceIdsFrom = [Number(deviceIds)];
      }
    },
  },
};
</script>
<style scoped lang="less" src="../../style/public.less"></style>

<style lang="less" scoped>
@import "../../style/colorPublic";
.operationButton {
  text-align: right;
}
.butColor {
   background: @but_color;
}
.conter {
  margin-top: 2vh;
  display: flex;
  .essentialImg {
    width: 20vh;
    border: 1px solid #ccc;
    height: 20vh;
    margin-right: 3vh;
    border-radius: 5px;
  }
}
.essential {
  flex: 1;
  width: 30vw;
  //   border: 1px solid green;
  h3 {
    color: black;
    font-size: 2.1vh;
    font-weight: 500;
    margin: 1vh 0;
  }
}
.titleFormCon {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}
.titleForm {
  display: flex;
  position: relative;
}
.batchBox {
  display: flex;
  position: relative;
  flex-wrap: wrap;
}
.grow {
  display: flex;
  justify-content: space-between;
}
.written {
  border: 0;
  outline: 0;
}
.growBut {
  height: 3vh;
}
// 流程信息
.gowthStageItemBox {
  margin: 1vh 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 35vw;
  //   max-height: 60vh;
  overflow: hidden;
  height: 30vh;
    overflow-y: auto;
}
.gowthStageItem {
  border: 1px solid #ccc;
  width: 20vh;
  height: 28vh;
  margin: 0 2.5vh 2vh 0;
}
.imgUpload {
  width: 15vh;
  height: 15vh;
  border: 1px solid #ccc;
  margin: 2vh auto 1vh auto;
}
.imgUpload .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.imgUpload .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.imgUpload .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 15vh;
  line-height: 15vh;
  text-align: center;
}
.imgUpload .avatar {
  width: 15vh;
  height: 15vh;
  display: block;
}
.phase /deep/ .el-input__inner {
  width: 15vh;
  height: 3vh !important;
  line-height: 3vh !important;
}
.phase /deep/ .el-form-item {
  text-align: center;
  margin-bottom: 0;
}
.phase /deep/ .el-button--default {
  height: 3vh !important;
  padding: 9px 19px;
}

.addGowthStage {
  margin: 0 2vh;
  span {
    display: block;
    cursor: pointer;
    font-size: 6vh;
  }
}
.information {
  height: 50vh;
  overflow: hidden;
  overflow-y: auto;
}
.proctImg {
  margin: 0;
}
.proctImg .avatar {
  width: 20vh;
  height: 20vh;
  display: block;
}
.proctImg .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 20vh;
  line-height: 20vh;
  text-align: center;
}
.plantingClassInput /deep/ .el-date-editor{
  width: 100%;
}
@media screen and (max-width: 1500px) {
  .growBut {
    height: 4vh;
  }
  .phase /deep/ .el-form-item__content {
    line-height: 27px;
  }
  .phase /deep/ .el-form-item {
    margin-bottom: 0 !important;
    text-align: center;
  }
  .gowthStageItemBox{
      height: auto;
  }
}
.public /deep/ .el-form-item {
  margin-bottom: 2vh !important;
}
</style>
