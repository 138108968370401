<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:36:55
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-01-13 16:00:09
 * @Description: 
-->
<template>
  <div class="productionManagement"> 
    <!-- <div class="draw-title">
      <el-button class="butStyle" size="mini" @click="newPlot">新建批次</el-button>
      <el-button v-if="saveState == 1" class="butStyle" size="mini" @click="eaitNewPlot">编辑</el-button>
      <el-button v-if="saveState == 0" class="butStyle" size="mini" @click="saveNewPlot">保存</el-button>
      <el-button class="butStyle" size="mini" @click="removeNewPlot">删除</el-button>
    </div> -->
    <drawMap @resetBut="resetBut" @eaitState="eaitState" @openState="openState" @openPopup="openPopup" ref="drawMap" />
    <el-collapse-transition>
      <div class="public modelBox" v-show="modelState"> 
        <div class="titleBox">
          <p>审核</p>
          <i @click="modelState = false" class="closeIcon el-icon-close"></i>
        </div>
        <el-form class="el-zoom-in-top" ref="examineFrom" :model="examineFrom" label-width="80px">
          <el-form-item label="种植批次">
            <el-input v-model="examineFrom.batchNumber" disabled></el-input>
          </el-form-item>
          <el-form-item label="作物名称">
            <!-- <el-input v-model="examineFrom.productName" disabled></el-input> -->
            <el-input v-model="examineFrom.plantName" disabled></el-input>
          </el-form-item>
          <el-form-item label="地块名称">
            <el-input v-model="examineFrom.plotName" disabled></el-input>
          </el-form-item>
          <el-form-item label="面积信息">
            <el-input v-model="examineFrom.plantingArea" disabled></el-input>
          </el-form-item>
          <el-form-item label="种植时间">
            <el-input v-model="examineFrom.plantingTime" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="operationBut">
          <el-button @click="adoptBatch(3)" size="small">驳回</el-button>
          <el-button @click="adoptBatch(2)" size="small">通过</el-button>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script>
import drawMap from '@/components/soureUnit/examineDraw.vue';
import cropModel from '@/components/soureUnit/cropBatch.vue';
export default {
  components: { drawMap, cropModel },
  data() {
    return {
      saveState: 1,
      cropsList: [],
      massifList: [],
      modelState: false,
      batchWkt: [],
      productCycle: null,
      nonBatch: 'add',
      examineFrom: {},
    };
  },

  mounted() {
    // this.$refs.drawMap.ruquestBatch();
    this.$get('/productCycle/qurryProductCycle', { page: 1, size: 100000 }).then((res) => {
      if (res.data.state == 'success') {
        this.cropsList = res.data.datas;
      }
    });
    this.$get('/enterpriseManage/querryLandPlot', { page: 1, size: 100000 }).then((res) => {
      if (res.data.state == 'success') {
        this.massifList = res.data.datas;
        //console.log(this.massifList);
      }
    });
  },
  methods: {
    newPlot() {
      this.$refs.drawMap.batch();
    },
    eaitNewPlot() {
      this.$refs.drawMap.batchModification();
      this.nonBatch = 'add';
    },
    /**
     * @description: 编辑批次信息
     * @param {*}
     */

    saveNewPlot() {
      //console.log(this.$refs.drawMap.polygonWkt);
      this.saveState = 1;
      this.modelState = true;
      this.$nextTick(() => {
        this.$refs.modelCom.echoPlantingBatch();
        this.nonBatch = 'eait';
      });
    },
    /**
     * @description:  修改批次超出范围重置按钮
     * @param {*}
     */

    resetBut() {
      this.saveState = 1;
    },
    removeNewPlot() {
      let data = this.$refs.drawMap.selectedFeature;
      if (!data) return this.$message.info('请选择批次进行删除');
      if (data[0].explain != 'batch') return this.$message.info('地块不可删除');
      this.$confirm('此操作将永久删除该批次, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/plantingBatch/delPlantingBatch', { id: data[0].massifId }).then((res) => {
            //console.log(res);
            if (res.data.state == 'success') {
              this.$message.success('删除成功');
              this.$refs.drawMap.batchDelect(data[0]);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // exits(addAndEait) {
    //   this.$refs.drawMap.batchPopup(addAndEait);
    //   this.modelState = false;
    // },
    /**
     * @description:  绘制批次信息
     * @param {*} batchWkt 地块 经纬度 name id
     */

    openState(batchWkt) {
      //   this.modelState = true;
      this.batchWkt = batchWkt;
      //console.log(this.batchWkt);
    },
    /**
     * @description: 回显
     * @param {*} id 数据
     */

    openPopup(data) {
      //   this.$get('/plantingBatch/fatchPlantingBatch', { id }).then((res) => {
      //     if (res.data.state == 'success') {

      //     }
      //   });
      this.examineFrom = data.plantingBatchBean;
      //console.log(data.plantingBatchBean)
      this.modelState = true;
    },
    /**
     * @description: 确定
     * @param {*}
     */

    adoptBatch(state) {
      //console.log(this.examineFrom);
      let { id, plotId } = this.examineFrom;
      this.$get('/plantingBatch/updatePlantingBatch', { id, state, plotId }).then((res) => {
        //console.log(res);
        if (res.data.state == 'success') {
          this.$message.success('操作成功');
          this.modelState = false;
           this.$refs.drawMap.removeBatch();
        }
      });
    },
    eaitState(id) {
      this.saveState = 0;
      this.$get('/plantingBatch/fatchPlantingBatch', { id }).then((res) => {
        //console.log(res);
        if (res.data.state == 'success') {
          this.productCycle = res.data.data;
        }
      });
    },
    /**
     * @description:
     * @return {*} String     processInfo json
     * @return {*} String     location  经纬度位置
     * @return {*} String     productImg 生长周期图片（最外层）
     * @return {*} String    state     产品状态:0待审核，1申请中，2审核通过，3审核驳回
     * @return {*} Timestamp  plantingTime,	种植时间
     * @return {*} String    plantingArea,   地块面积
     * @return {*} String     plotName,		地块名称
     * @return {*} String    plotName,		地块名称
     * @return {*} String    batchNumber,	批次号
       @return {*} String    productName,	作物名称
       @return {*} String    plantType,		作物类别
       @return {*} long      plotId,		地块id
     */

    // saveFrom(data, state) {
    //   //console.log('saveFrom', data);
    //   //console.log(this.batchWkt);

    //   let url, location;
    //   if (state == 'add') {
    //     url = '/plantingBatch/addPlantingBatch';
    //     location = this.batchWkt.batchWkt.wkt[0];
    //   } else {
    //     url = '/plantingBatch/updatePlantingBatch';
    //     // location = JSON.stringify(this.$refs.drawMap.polygonWkt.wkt);
    //     location = this.$refs.drawMap.polygonWkt.wkt[0];
    //     //console.log(location, '138');
    //     //console.log(data, '138');
    //     data.id = this.$refs.drawMap.polygonWkt.id;
    //     // data.plotId = data.massifId;
    //     delete data.del;
    //     delete data.createTime;
    //     delete data.productCycleId;
    //     if (this.$refs.drawMap.polygonWkt) delete data.location;
    //   }
    //   data.processInfo = JSON.stringify(data.processInfo);
    //   this.$post(url, {
    //     location,
    //     plotId: this.batchWkt.id,
    //     plotName: this.batchWkt.name,
    //     ...data,
    //     state: 0,
    //   }).then((res) => {
    //     if (res.data.state == 'success') {
    //       this.$message.success('添加批次成功');
    //       this.modelState = false;
    //       this.$refs.drawMap.ruquestBatch();
    //       this.$refs.drawMap.addBatchSuccess();
    //     }
    //     //console.log(res);
    //   });
    // },
  },
};
</script>
<style lang="less" scoped>
.productionManagement {
  width: 100%;
  height: 100vh;
}
.draw-title {
  position: absolute;
  top: 5vh;
  left: 50%;
  width: 97%;
  height: 7vh;
  background: #fff;
  z-index: 999999;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  line-height: 7vh;
  padding-left: 3vh;
  box-sizing: border-box;
}
.butStyle {
  background: #03a9f4;
  color: #fff;
}
.modelBox {
  position: absolute;
  z-index: 1111;
  top: 55%;
  background: #fff;
  left: 50%;
  width: 29vw;
  /* height: 69vh; */
  transform: translate(-50%, -50%);
  padding: 0vh 4vh 4vh 4vh;
  box-sizing: border-box;
}
.modelBox /deep/ .gowthStageItemBox {
  width: 100%;
}
.titleBox {
  text-align: left;
  margin-bottom: 2vh;
  border-bottom: 1px solid #ccc;
  padding: 2vh;
  box-sizing: border-box;
  font-size: 1.5vh;
  display: flex;
  justify-content: space-between;
}
.closeIcon {
  font-size: 2vh;
  cursor: pointer;
}
@media screen and (max-width: 1500px) {
  .closeIcon {
    font-size: 2.5vh;
  }
  .modelBox {
    position: absolute;
    z-index: 1111;
    top: 55%;
    background: #fff;
    left: 50%;
    width: 33vw;
    /* height: 69vh; */
    transform: translate(-50%, -50%);
    padding: 0vh 4vh 4vh 4vh;
    box-sizing: border-box;
  }
  .modelBox /deep/ .el-input__inner {
    height: 5vh !important;
    line-height: 5vh !important;
  }
  .modelBox /deep/ .el-form-item {
    margin-bottom: 2vh;
  }
}
.operationBut {
  text-align: right;
}
</style>
